import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';
import Tippy from '@tippy.js/react'
class Navi extends Component {
  constructor() {
    super();
    this.state = {
      message: "Click to copy email address",
      duration: 500
    }
  }
  copied(e){
    this.setState({ message: "Copied!"})
    const el = document.createElement('textarea');
    el.value = "benito.mazar@gmail.com";
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    
  }
  render() {
    
    return (
    	<div className="wrapper">
          <NavLink  className="link" to={'/about'}>Who?</NavLink>	
            <Tippy  delay={[0, this.state.duration]} hideOnClick='false' content={this.state.message} arrow='true'>
    <button onClick={this.copied.bind(this)}><i className="fas fa-envelope"></i></button>
  </Tippy>
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/BenMz"><i className="fab fa-github"></i></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/benito-maza"><i className="fab fa-linkedin"></i></a>
      </div>
    );
  }
}

export default Navi;
