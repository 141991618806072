import React, { Component } from 'react';
import AboutMe from './AboutMe';
import Header from './Header';
import Navi from './Navi';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose';
import '../css/App.css';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <div className="App">
        <div className="blacky">
          <nav className="App-nav">
            
              <Navi/>
            
          </nav>
          <Route render={({ location }) => (
            <div className="App-main">
            <PoseGroup>
              <RouteContainer key={location.pathname}>
                <Switch location={location}>
                  <Route exact path="/" component={Header} key="home" />
                  <Route  path="/about" component={AboutMe} key="about"/>
                </Switch>
              </RouteContainer>
            </PoseGroup>
            </div>
          )}
          />

            
        </div>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
