import React, { Component } from 'react';

class AboutMe extends Component {
  render() {
    return (
        <div>
          <section className="about section"> 
            <h2 className="text-left">Benito Maza</h2> 
              <img className="circular-picture float-left" src="/img/yo.jpg" alt="Ben"/> 
              <p>I'm a Full-Stack Engineer residing in Guatemala City, Central America. I have a BS in Computer Science and Information Technology Engineering. I've worked in a wide variety of industries and always enjoy trying and challenging myself with new projects.</p>
              <p>Besides coding, I enjoy going for a jog <span role="img"aria-label="emoji">🏃</span> , traveling and learning about different countries <span role="img"aria-label="emoji">✈️</span> , have a taste of amazing food and desserts <span role="img"aria-label="emoji">🥞</span> , and savor a great cup of coffee <span role="img"aria-label="emoji">☕</span>. </p> 
              <p>You can contact me through LinkedIn or by email.</p>
            </section>
          <section className="tech section"> 
            <h2 className="text-right">Tech Stack</h2> 
              <div className="container">
                
                  <div className="col-1 row-1"><p>Currently using</p></div>
                  <div className="col-2 row-1"><p>
                    Back-end: PHP(Laravel), NodeJS, Go;<br/> Front-end: Vue.js;<br/> Scripting: Go, Python; <br/>Mobile: Kotlin
                    <br/>Databases: MySQL, MongoDB, Redis, DynamoDB
                    <br/>DevOps: AWS, Kubernetes, Docker, Jenkins, Webpack
                  </p></div>
                  <div className='col-3 row-1'>
                    <p><i className="fas fa-layer-group"></i></p>
                  </div>
                  <div className="col-1 row-2"><p>Have used in the past year</p></div>
                  <div className="col-2 row-2"><p>
                    Back-end: Python(Django, Flask), Java, Ruby on Rails; <br/>Front-end: ReactJS
                    <br/>Databases: PostgreSQL
                   <br/> DevOps: Gulp, GitLab
                  </p></div>
                  <div className="col-1 row-3"><p>Currently learning</p></div>
                  <div className="col-2 row-3"><p>
                    GraphQL, TypeScript, Elixir
                  </p></div>
                
              </div>

            </section>
        </div>
    );
  }
}

export default AboutMe;