import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <div className="header">
            <h1>Benito Maza</h1>
            <h2>
              Software Engineer | Traveler | Geek | Foodie
            </h2>
            </div>
    );
  }
}

export default Header;
